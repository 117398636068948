<template>
  <div>
    <div
      class="outerOne settingStyle"
      :style="
        'backgroundImage:url(' + require('@/assets/img/indexBJ1.jpg') + ')'
      "
    >
      <div class="banner" v-if="mediaNews.length > 0">
        <swiper :options="swiperOption" ref="videoSwiper" v-if="initOrNot && mediaNews.length > 1">
          <swiper-slide v-for="(item, index) in mediaNews" :key="index">
            <video
              v-if="item.type == 2"
              :src="item.image"
              controls
              muted
              @ended="endVideo(index)"
              class="multimedia"
              style="height: 100%"
            ></video>
            <img
              v-else
              :src="item.image"
              class="multimedia"
              @click="jumpBannerInfo(item)"
            />
          </swiper-slide>
          <div
            class="swiper-pagination"
            v-for="(item, index) in mediaNews"
            :key="index"
            slot="pagination"
          ></div>
        </swiper>

        <div v-for="(item, index) in mediaNews" :key="index" v-if="initOrNot && mediaNews.length < 2">
            <video
              v-if="item.type == 2"
              :src="item.image"
              controls
              muted
              @ended="endVideo(index)"
              class="multimedia"
              style="height: 100%"
            ></video>
            <img
              v-else
              :src="item.image"
              class="multimedia"
              @click="jumpBannerInfo(item)"
            />
          </div>

      </div>
      <div class="modular">
        <div
          v-for="(item, index) in $t('index.index_modular')"
          :key="index"
          class="modularItem"
          @click="industryService(item)"
        >
          <img :src="item.icon" alt="" />
          <div>
            <div class="titleOne">{{ item.title }}</div>
            <div class="titleTwo">{{ item.titleTwo }}</div>
          </div>
        </div>
      </div>
      <div class="recommend">
        <div class="everydayTJ">
          <div v-if="everydayTJ.length > 0">
            <div class="tip">{{ $t("index.index_everydayTJ") }}</div>
            <div class="tjImg">
              <img :src="everydayTJ[0].image" alt="" class="imgHoverBig" />
            </div>
            <div
              class="tjTitle"
              v-for="(item, index) in everydayTJ"
              :key="index"
              @click="newsDetails(item)"
            >
              <div>
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>

        <div class="headlines">
          <el-carousel trigger="click" arrow="never" class="headlinesCarounsel">
            <el-carousel-item v-for="(item, index) in headlines" :key="index">
              <div class="linesImg">
                <img :src="item.image" alt="" class="imgHoverBig" />
              </div>
              <div class="linesBox" @click="newsDetails(item)">
                <div class="linesTip">
                  <span></span>
                  <div>{{$t("index.index_news")}}</div>
                  <span></span>
                </div>
                <div class="linesTitle">
                  {{ item.title }}
                </div>
                <div class="linesTitleTwo" v-html="item.content"></div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>

        <div class="everydayTJ everydayTJTwo">
          <div v-if="hotspotTJ.length > 0">
            <div class="tip">{{ $t("index.index_hotspotTJ") }}</div>
            <div class="tjImg">
              <img :src="hotspotTJ[0].image" alt="" class="imgHoverBig" />
            </div>
            <div
              class="tjTitle"
              v-for="(item, index) in hotspotTJ"
              :key="index"
              @click="newsDetails(item)"
            >
              <div>{{ item.title }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="outerOne outerTwo settingStyle"
      :style="
        'backgroundImage:url(' + require('@/assets/img/indexBJ2.jpg') + ')'
      "
    >
      <div class="instituteLeft" @click="briefIntroduction()">
        <div class="instituteOne">{{ $t("index.index_instituteOne") }}</div>
        <div class="instituteTwo" v-html="institute"></div>
        <div class="instituteThree">
          <div></div>
          <div>{{ $t("index.index_more") }}</div>
        </div>
      </div>
      <div class="instituteRight">
        <div
          class="iRightCont"
          @mouseenter="instituteEntersOne()"
          @mouseleave="instituteLeaverOne()"
          @click="iRightCont(1)"
        >
          <img src="@/assets/img/riBJ1.png" alt="" />
          <div
            class="instituteHover settingStyle"
            v-if="isInstituteHoverOne == true"
            :style="
              'backgroundImage:url(' +
              require('@/assets/img/riBJHover.png') +
              ')'
            "
          >
            <div v-html="$t('index.index_instituteTwo')"></div>
          </div>
        </div>
        <div
          class="iRightCont"
          @mouseenter="instituteEntersTwo()"
          @mouseleave="instituteLeaverTwo()"
          @click="iRightCont(2)"
        >
          <img src="@/assets/img/riBJ2.png" alt="" />
          <div
            class="instituteHover settingStyle"
            v-if="isInstituteHoverTwo == true"
            :style="
              'backgroundImage:url(' +
              require('@/assets/img/riBJHover.png') +
              ')'
            "
          >
            <div v-html="$t('index.index_instituteThree')"></div>
          </div>
        </div>
        <div
          class="iRightCont"
          @mouseenter="instituteEntersThree()"
          @mouseleave="instituteLeaverThree()"
          @click="iRightCont(3)"
        >
          <img src="@/assets/img/riBJ3.png" alt="" />
          <div
            class="instituteHover settingStyle"
            v-if="isInstituteHoverThree == true"
            :style="
              'backgroundImage:url(' +
              require('@/assets/img/riBJHover.png') +
              ')'
            "
          >
            <div v-html="$t('index.index_instituteFour')"></div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="outerOne outerThree settingStyle"
      :style="
        'backgroundImage:url(' + require('@/assets/img/indexBJ3.jpg') + ')'
      "
    >
      <div class="RDprojectTitle">
        <div class="RDprojectLeft">
          <div class="titleOne">{{ $t("index.index_RDprojectOne") }}</div>
          <div class="titleTwo">{{ $t("index.index_RDprojectTwo") }}</div>
        </div>
        <div class="more" @click="researchDirection()">
          {{ $t("index.index_more") }}
        </div>
      </div>
      <div class="projectList" v-if="project.length > 0">
        <div
          v-for="(item, index) in project"
          :key="index"
          @mouseenter="enters(index)"
          @mouseleave="leaver(index)"
          @click="projectDetail(item)"
        >
          <img :src="item.image" alt="" v-if="index < 4" />
          <div class="itemHoverCont" v-if="item.isHover == true">
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>

    <div class="outerOne outerFour settingStyle">
      <div class="academy" @click="departmentSettings()">
        <img src="@/assets/img/indexBJ4.jpg" alt="" class="imgHoverBig" />
        <div class="tip">{{ $t("index.index_academy") }}</div>
        <div class="xian"></div>
      </div>
      <div class="learning" @click="academicActivities">
        <img src="@/assets/img/indexBJ44.jpg" alt="" class="imgHoverBig" />
        <div class="xian"></div>
        <div class="tip">{{ $t("index.index_learning") }} &nbsp;</div>
      </div>
    </div>

    <div
      class="outerOne outerFive settingStyle"
      :style="
        'backgroundImage:url(' + require('@/assets/img/indexBJ5.jpg') + ')'
      "
      v-if="recruitList.length > 0"
    >
      <div class="topOne">
        <img src="@/assets/img/carrier.png" alt="" class="carrierImg" />
        <div class="carrier">
          <div class="carrierOne">招贤纳士</div>
          <div class="carrierTwo">Career</div>
        </div>
      </div>
      <div class="carrCont">
        <div
          v-for="(item, index) in recruitList"
          :key="index"
          :style="
            'backgroundImage:url(' + require('@/assets/img/indexBJ6.png') + ')'
          "
          class="home7 settingStyle"
          @click="talentRecruitment(item)"
        >
          <div>
            <div v-html="$t('index.index_employ')" class="employ"></div>
            <div class="titleOne">{{ item.title }}</div>
            <div class="content">专业：{{ item.zhuany }}</div>
            <div class="more">{{ $t("index.index_more") }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：首页
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-08-06 09:55
 */
import currentLocation from "@/components/currentLocation.vue";
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
  components: {
    currentLocation,
    swiper,
    swiperSlide,
  },
  data() {
    return {
      sibItem: {
        name: 111,
      },
      everydayTJ: "", // 每日推荐
      hotspotTJ: "", // 热点推荐
      headlines: "", // 头条新闻
      institute: "", // 研究院介绍
      isInstituteHoverOne: false,
      isInstituteHoverTwo: false,
      isInstituteHoverThree: false,
      project: "", // 研发项目
      recruitList: "", // 招贤纳士

      mediaNews: [],
      mediaLastIndex: 0,
      swiperOption: {
        //initialSlide: 0,
        loop: true,
        speed: 1000,
        autoplay: false,
        // grabCursor: true,
        setWrapperSize: true,
        autoHeight: true,
        uniqueNavElements: true,
        preventInteractionOnTransition: false,
        allowSilderPrev: true,
        allowSilderNext: true,
        //   scrollbar:'.swiper-scrollbar',//滚动条
        mousewheelControl: true,
        observer: true,
        observeParents: true,
        debugger: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        on: {
          // //控制第一个slide切换
          init: () => {
            this.initHandle();
          },
          slideChangeTransitionStart: () => {
            this.slideChangeTransitionStartHandle();
          },
          slideChangeTransitionEnd: () => {
            this.slideChangeTransitionEndHandle();
          },
        },
      },
      initOrNot: false, //mediaNews数据改变的时候确保swiper会init一次
    };
  },
  watch: {
    mediaNews: {
      handler(newName, oldName) {
        this.initOrNot = false;
        this.$nextTick(() => {
          this.initOrNot = true;
        });
      },
      immediate: true,
      deep: true,
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScrollbox, true);
  },
  mounted() {
    this.getHomePage();
    window.addEventListener("scroll", this.handleScrollbox, true);
  },
  methods: {
    /**
     * 首页数据
     * 刘嘉鑫
     * 2022-8-16
     */
    getHomePage() {
      this.$request({
        url: "/Index/index",
        data: {},
      }).then((res) => {
        console.log("首页", res);
        var reg = /[\u4e00-\u9fa5]/g;
        var reg2 = new RegExp("<.+?>", "g");
        this.mediaNews = res.adv_list;
        this.everydayTJ = res.tuijian;
        this.hotspotTJ = res.redian;
        this.institute = res.introduce.replace(reg2, "");
        this.recruitList = res.recruit_list.slice(0, 3);

        for (let i in res.toutiao) {
          // res.toutiao[i].content = res.toutiao[i].content.match(reg).join("");
          res.toutiao[i].content = res.toutiao[i].content.replace(reg2, "");
        }
        this.headlines = res.toutiao;

        for (let a in res.project_list) {
          res.project_list[a].isHover = false;
        }
        this.project = res.project_list;
      });
    },

    /**
     * 轮播图详情
     * 刘嘉鑫
     * 2022-9-5
     */
    jumpBannerInfo(item) {
      // 图片跳转类型:1=新闻中心,2=研发项目,3=人才招聘
      if (item.jump_type == 1) {
        this.newsDetails({ id: item.jump_id });
      }
      if (item.jump_type == 2) {
        this.projectDetail({ id: item.jump_id });
      }
      if (item.jump_type == 3) {
        this.talentRecruitment({ id: item.jump_id });
      }
      if (item.jump_type == 4) {
        this.$router.push({
          path: "/bannerDetails",
          query: {
            info: JSON.stringify(item),
            type: 1,
          },
        });
      }
    },

    /**
     * 研发服务，人才培养，专利联盟，学术活动，行业科普
     * 刘嘉鑫
     * 2022-8-24
     */
    industryService(item) {
      this.$router.push({
        path: item.path,
      });
    },

    initHandle() {
      let that = this;
      // console.log(this.mediaNews)
      setTimeout(function () {
        let swiper = that.$refs.videoSwiper.swiper;
        // console.log(swiper);
        if (that.mediaNews[0]?.type == 1) {
          that.mediaNewsImgHandle(swiper);
        } else {
          document
            .getElementsByClassName("multimedia")
            [swiper.activeIndex]?.play();
          // document.getElementsByClassName("multimedia")[0]?.play();
        }
      }, 200);
    },
    mediaNewsImgHandle(swiper) {
      let that = this;
      //刚切换到的activeIndex
      let changePointActiveIndex = swiper.realIndex;
      if (swiper.realIndex < this.mediaNews.length - 1) {
        // console.log("swiper.realIndex < this.mediaNews.length - 1");
        setTimeout(function () {
          //要确认changePointActiveIndex是不是还是目前的activeIndex，是的话计时后执行，不是的话不执行
          if (changePointActiveIndex == swiper.realIndex) {
            swiper.slideNext();
          }
        }, 5000);
      } else {
        // console.log("swiper.realIndex > this.mediaNews.length - 1");
        setTimeout(function () {
          // console.log(
          //   "changePointActiveIndex == swiper.realIndex",
          //   changePointActiveIndex,swiper.realIndex
          // );
          // if (changePointActiveIndex == swiper.realIndex) {
          //   swiper.slideTo(0, 1000);
          // }
          swiper.slideNext();
        }, 5000);
      }
    },
    slideChangeTransitionStartHandle() {
      let that = this;
      setTimeout(function () {
        let swiper = that.$refs.videoSwiper.swiper;
        if (that.mediaNews[swiper.realIndex].type == 1) {
          document.getElementsByClassName("multimedia")[
            swiper.realIndex
          ].currentTime = 0;
        } else {
          // document
          //   .getElementsByClassName("multimedia")
          //   [swiper.activeIndex].play();
        }
      }, 200);
    },
    slideChangeTransitionEndHandle() {
      // type 1=图片  2=视频
      let that = this;
      console.log("end..");
      setTimeout(function () {
        let swiper = that.$refs.videoSwiper.swiper;
        // console.log("type", that.mediaNews[swiper.realIndex].type);
        if (that.mediaNews[swiper.realIndex].type == 2) {
          document
            .getElementsByClassName("multimedia")
            [swiper.activeIndex].pause();
        }
        if (that.mediaNews[swiper.realIndex].type == 1) {
          that.mediaNewsImgHandle(swiper);
        } else {
          document
            .getElementsByClassName("multimedia")
            [swiper.activeIndex].play();

          document
            .getElementsByClassName("multimedia")
            [swiper.activeIndex].addEventListener(
              "ended",
              () => {
                //结束
                console.log("播放结束");
                // clearInterval(this.timer);
                that.endVideo(swiper.realIndex);
              },
              false
            );
        }
        that.mediaLastIndex = swiper.realIndex;
      }, 200);
    },
    endVideo(index) {
      // console.log("endVideo", index);
      let swiper = this.$refs.videoSwiper.swiper;
      if (index === swiper.realIndex) {
        swiper.slideNext();
        if (this.mediaNews[swiper.realIndex].type == 2) {
          document
            .getElementsByClassName("multimedia")
            [swiper.activeIndex].play();
        } else {
          this.mediaNewsImgHandle(swiper);
        }
        // if (swiper.realIndex < this.mediaNews.length - 1) {
        //   console.log(123)
        //   swiper.slideNext();
        //   if (this.mediaNews[swiper.realIndex].type == 2) {
        //     document
        //       .getElementsByClassName("multimedia")
        //       [swiper.activeIndex].play();
        //   } else {
        //     this.mediaNewsImgHandle(swiper);
        //   }
        // } else {
        //   console.log(456)
        //   swiper.slideTo(0, 0);
        //   if (this.mediaNews[swiper.realIndex].type == 2) {
        //     document
        //       .getElementsByClassName("multimedia")
        //       [swiper.activeIndex].play();
        //   } else {
        //     this.mediaNewsImgHandle(swiper);
        //   }
        // }
      }
    },

    /**
     * 动画效果
     * 刘嘉鑫
     * 2022-8-23
     */
    handleScrollbox() {
      this.currentScroll = window.pageYOffset; //表示当前滚动的位置

      // 研究院介绍
      if (this.currentScroll >= this.$(".outerTwo").offset().top - 950) {
        this.$(".instituteRight>div").addClass("showDiv");
      }

      //  招贤纳士
      if (this.currentScroll >= this.$(".carrCont").offset().top - 950) {
        this.$(".home7").addClass("showDiv");
      }

      // 研发项目
      if (this.currentScroll >= this.$(".projectList").offset().top - 950) {
        this.$(".projectList>div").addClass("showDiv");
      }
    },

    /**
     * 每日推荐，头条新闻
     * 刘嘉鑫
     * 2022-8-25
     */
    newsDetails(item) {
      this.$router.push({
        path: "/newsCenterDetails",
        query: {
          id: item.id,
        },
      });
    },

    /**
     * 研究院介绍
     * 刘嘉鑫
     * 2022-8-25
     */
    briefIntroduction() {
      this.$router.push({
        path: "/briefIntroduction",
      });
    },

    /**
     * 研发项目
     * 刘嘉鑫
     * 2022-8-25
     */
    researchDirection() {
      this.$router.push({
        path: "/researchDirection",
      });
    },

    /**
     * 研发项目动画效果
     * 刘嘉鑫
     * 2022-8-30
     */
    enters(index) {
      this.project[index].isHover = true; // 当前鼠标悬浮所在的图片隐藏
    },
    leaver(index) {
      this.project[index].isHover = false; // 文字隐藏
    },

    /**
     * 研究院介绍动画效果
     * 刘嘉鑫
     * 2022-8-30
     */
    instituteEntersOne() {
      this.isInstituteHoverOne = true; // 当前鼠标悬浮所在的图片隐藏
    },
    instituteLeaverOne() {
      this.isInstituteHoverOne = false; // 文字隐藏
    },
    instituteEntersTwo() {
      this.isInstituteHoverTwo = true; // 当前鼠标悬浮所在的图片隐藏
    },
    instituteLeaverTwo() {
      this.isInstituteHoverTwo = false; // 文字隐藏
    },
    instituteEntersThree() {
      this.isInstituteHoverThree = true; // 当前鼠标悬浮所在的图片隐藏
    },
    instituteLeaverThree() {
      this.isInstituteHoverThree = false; // 文字隐藏
    },

    /**
     * 研究院介绍图片跳转
     * 刘嘉鑫
     * 2022-9-19
     */
    iRightCont(type) {
      // type  1:使命愿景价值观  2:我们的团队  3:资质荣誉
      if (type == 1) {
        this.$router.push({
          path: "/missionvision-values",
        });
      }
      if (type == 2) {
        this.$router.push({
          path: "/ourTeam",
        });
      }
      if (type == 3) {
        this.$router.push({
          path: "/qualificationHonor",
        });
      }
    },

    /**
     * 研发项目详情
     * 刘嘉鑫
     * 2022-8-30
     */
    projectDetail(item) {
      this.$router.push({
        path: "/RDproject",
        query: {
          id: item.id,
        },
      });
    },

    /**
     * 研究院部门
     * 刘嘉鑫
     * 2022-8-25
     */
    departmentSettings() {
      this.$router.push({
        path: "/departmentSettings",
      });
    },

    /**
     * 学术活动
     * 刘嘉鑫
     * 2022-8-25
     */
    academicActivities() {
      this.$router.push({
        path: "/academicActivities",
      });
    },

    /**
     * 招贤纳士
     * 刘嘉鑫
     * 2022-8-25
     */
    talentRecruitment(item) {
      this.$router.push({
        path: "/talentRecruitment",
        query: {
          id: item.id,
        },
      });
    },
  },
};
</script>

<style>
.headlinesCarounsel {
  height: 100% !important;
}
.headlinesCarounsel > div {
  height: 100% !important;
}

.headlinesCarounsel .el-carousel__button {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50% !important;
  background: rgba(27, 43, 79, 1) !important;
}
.headlinesCarounsel .el-carousel__indicators {
  margin-bottom: 10px !important;
}

.swiper-pagination-bullet-active {
  background: #1a2a60 !important;
}
</style>

<style lang='scss' scoped>
.outerOne {
  padding: 0 179px 100px;
}
.banner {
  width: 100%;
  height: 500px;
  // padding: 0 32px;

  img,
  .swiper-container,
  video,
  .swiper-slide {
    width: 100%;
    height: 100%;
  }
}

.modular {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 78px 0;
  .modularItem {
    display: flex;
    align-items: center;
    width: 13.5%;
    img {
      width: 36px;
      height: 36px;
      margin-right: 14px;
    }

    .titleOne {
      font-size: 18px;
      font-family: FZFengYaSongS-GB;
      font-weight: bold;
      color: #1b2b4f;
      margin-bottom: 6px;
    }

    .titleTwo {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #5e6374;
    }
  }
}

.recommend {
  display: flex;
  justify-content: space-between;
  padding: 42px 31px 0;

  .everydayTJ {
    width: 19%;
    padding-top: 80px;
    .tip {
      font-size: 18px;
      font-family: FZFengYaSongS-GB;
      font-weight: bold;
      color: #1a2a60;
      margin-bottom: 14px;
    }

    .tjImg {
      width: 280px;
      height: 157px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .tjTitle {
      margin-top: 30px;
      padding-bottom: 19px;
      border-bottom: 2px solid #dddddd;
      cursor: pointer;
      > div {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #222222;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        word-break: break-all;
        white-space: normal !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }

  .headlines {
    width: 45%;
    height: 720px;
    .linesImg {
      width: 100%;
      height: 410px;
      // border: 4px solid rgba(27, 43, 79, 0.2);
      overflow: hidden;
      display: block;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .linesBox {
      background: #ffffff;
      padding: 20px 40px 70px 35%;
      margin-top: 0px;
      cursor: pointer;
    }
    .linesTip {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 19px;
      > span {
        width: 17px;
        height: 2px;
        background: #1a2a60;
        margin: 0 9px;
        display: inline-block;
      }

      span:nth-child(2) {
        margin-left: 9px;
      }

      > div {
        font-size: 18px;
        font-family: FZFengYaSongS-GB;
        font-weight: bold;
        color: #1a2a60;
      }
    }

    .linesTitle {
      font-size: 26px;
      font-family: FZFengYaSongS-GB;
      font-weight: bold;
      color: #222222;
      margin-bottom: 29px;
      text-align: right;
    }

    .linesTitleTwo {
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      word-break: break-all;
      white-space: normal !important;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #888888;
    }
  }
}

.outerTwo {
  padding: 120px 260px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .instituteLeft {
    width: 24%;
    cursor: pointer;
  }

  .instituteOne {
    font-size: 30px;
    font-family: FZFengYaSongS-GB;
    font-weight: bold;
    color: #1a2a60;
    margin-bottom: 60px;
  }

  .instituteTwo {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #222222;
    line-height: 40px;
    margin-bottom: 39px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    word-break: break-all;
    white-space: break-spaces !important;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }

  .instituteThree {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #1a2a60;
    display: flex;
    align-items: center;

    div:nth-child(1) {
      width: 20px;
      height: 2px;
      background: #07257d;
      margin-right: 10px;
      display: inline-block;
    }
  }

  .instituteRight {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 60%;

    // div {
    //   overflow: hidden;
    //   position: relative;
    // }

    .iRightCont {
      width: 230px;
      height: 400px;
      overflow: hidden;
      position: relative;
      cursor: pointer;
      transition: all 1s ease-in 0s;
      transform: translateY(-80px);
      opacity: 0;

      > img {
        width: 100%;
        height: 100%;
        // cursor: pointer;
        // transition: all 1s ease-in 0s;
        // transform: translateY(-80px);
        // opacity: 0;
      }
    }
    .iRightCont:nth-child(1) {
      transform: translateY(-50px);
    }

    .iRightCont:nth-child(2) {
      transform: translateY(50px);
    }

    .iRightCont:nth-child(3) {
      transform: translateY(-50px);
    }

    .instituteHover {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: all 0.2s ease-out 0s;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 24px;
      color: #ffffff;
      > div {
        text-align: center;
      }
    }
  }
}

.outerThree {
  padding: 100px 260px !important;
  .RDprojectTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 60px;

    .RDprojectLeft {
      width: 70%;

      .titleOne {
        font-size: 30px;
        font-family: FZFengYaSongS-GB;
        font-weight: bold;
        color: #ffffff;
        margin-bottom: 20px;
      }

      .titleTwo {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        line-height: 24px;
        opacity: 0.6;
      }
    }

    .more {
      border: 1px solid #f4f4f4;
      border-radius: 4px;
      padding: 8px 16px;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
    }

    .more:hover {
      color: #1b2b4f !important;
      background: #ffffff !important;
    }
  }

  .projectList {
    border: 4px solid rgba(27, 43, 79, 0.6);
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.9);

    > div {
      overflow: hidden;
      position: relative;
      cursor: pointer;
      opacity: 0;
      filter: Alpha(opacity=100);
      transition: opacity 3s;
    }

    .itemHoverCont {
      background: rgba(12, 36, 85, 0.8);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transition: all 0.2s ease-out 0s;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      width: 280px;
      // height: 354px;
      height: 280px;
    }
  }
}

.outerFour {
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    display: flex;
    align-items: center;
    width: 50%;
    // padding: 47px 20px !important;
    height: 120px;
    cursor: pointer;
    position: relative;
    > .tip {
      font-size: 30px;
      font-family: FZFengYaSongS-GB;
      font-weight: bold;
      color: #ffffff;
    }
    .xian {
      width: 20px;
      height: 2px;
      background: #ffffff;
      margin: 0 20px 0 20px;
    }

    img {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      transition: all 0.9s !important;
    }
  }

  .academy {
    justify-content: flex-end;
    overflow: hidden;
  }

  .learning {
    justify-content: flex-start;
    overflow: hidden;
  }

  .academy:hover,
  .learning:hover {
    > img {
      transform: scale(1.2) !important;
    }
  }
}

.outerFive {
  padding: 120px 260px !important;

  .topOne {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 90px;

    .carrierImg {
      width: 60px;
      height: 60px;
      margin-right: 23px;
    }

    .carrier {
      .carrierOne {
        font-size: 30px;
        font-family: FZFengYaSongS-GB;
        font-weight: bold;
        color: #1a2a60;
        margin-bottom: 4px;
      }

      .carrierTwo {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #1a2a60;
      }
    }
  }

  .carrCont {
    display: flex;
    align-items: center;
    .home7 {
      overflow: hidden;
      transition: all 0.7s ease-out 0s;
      transform: translate(-100px, 0px);
      opacity: 0;
      padding: 30px 85px 40px 50px;
      width: 19%;
      height: 270px;
      cursor: pointer;
      margin-right: 100px;
      .employ {
        font-size: 24px;
        font-family: FZFengYaSongS-GB;
        font-weight: 400;
        color: #c5c5c5;
        margin-bottom: 30px;
      }

      .titleOne {
        font-size: 24px;
        font-family: FZFengYaSongS-GB;
        font-weight: 400;
        color: #1a2a60;
        margin-bottom: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .titleTwo {
        font-size: 16px;
        font-family: SimSun-ExtB;
        font-weight: 400;
        color: #1a2a60;
        margin-bottom: 27px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .content {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #888888;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        word-break: break-all;
        white-space: normal !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .more {
        margin-top: 50px;
        border: 1px solid #1b2b4f;
        border-radius: 4px;
        padding: 8px 16px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #1b2b4f;
        display: inline-block;
      }

      .more:hover {
        color: #ffffff !important;
        background: #1b2b4f !important;
      }
    }

    .home7:nth-child(1) {
      transition-delay: 0.3s;
    }
    .home7:nth-child(2) {
      transition-delay: 0.6s;
    }
    .home7:nth-child(3) {
      transition-delay: 0.9s;
      margin-right: 0 !important;
    }
  }
}
</style>